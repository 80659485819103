// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__anititle.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__hometab.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__barradiv.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__tabsnav.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__ustab.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__MVF.css");
var ___CSS_LOADER_AT_RULE_IMPORT_6___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__socialmed.css");
var ___CSS_LOADER_AT_RULE_IMPORT_7___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__counter.css");
var ___CSS_LOADER_AT_RULE_IMPORT_8___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__noms.css");
var ___CSS_LOADER_AT_RULE_IMPORT_9___ = require("-!../node_modules/css-loader/dist/cjs.js!./components/__contact.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);"]);
// Module
exports.push([module.id, "/* --------------------------------- Importa componentes --------------------------------- */\r\n\r\n/*Importa fuente*/\r\n/*@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');*/\r\n\r\n/* --------------------------------- ESTILOS CUERPO GENERAL --------------------------------- */\r\n \r\nbody {\r\n    background: #f0f0f0;\r\n    color: #2b2e71;\r\n    font: 400 12px/26px \"Montserrat\", sans-serif;\r\n    -webkit-text-size-adjust: none;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
